import React, { useState, useRef, ReactNode } from 'react'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup';
import { OfferionErrorMessage } from './validation/OfferionErrorMessage';
import { useOfferionTranslation } from '../store/hooks/useOfferionTranslation';

interface Props {
    as?: string,
    component?: string,
    placeholderKey?: string,
    children?: ReactNode,
    className?: string,
    currentValue?: string,
    onSave: ({ newValue }: { newValue: string }) => void
    validationSchema?: Yup.ObjectSchema;
    preventSubmitOnEnter?: boolean;
    hideError?: boolean;
    required?: boolean;
    // renders text into multiple lines if it contains multiline characters
    multiline?: boolean;
    // callback to be called when the field is submitted and is empty
    onEmptyField?: () => void;
}

export const InlineInputEdit = ({ currentValue, onSave, required, className, children, placeholderKey = '', component, as, validationSchema, preventSubmitOnEnter, hideError, multiline = false, onEmptyField }: Props) => {

    const [isEdit, setIsEdit] = useState(false);
    const fieldRef = useRef<HTMLInputElement>(null);
    const { t } = useOfferionTranslation()
    const isInputEmpty = !currentValue;
    const isMultiline = multiline

    const classes = `${isMultiline ? "text-multiline" : ""} ${isInputEmpty ? "input-empty-placeholder" : ""}`

    return <>
        <Formik validationSchema={validationSchema} enableReinitialize={true} initialValues={{ newValue: currentValue || "" }} onSubmit={(values, helpers) => {
            // We'll trim the value here, another option is to do that via the yup schema,
            // but doing that everywhere means we can easily forget that.
            const newValue = values.newValue.trim();
            
            if (newValue !== currentValue) {
                helpers.setValues({ newValue })
            }

            if (newValue === "" && onEmptyField) {
                onEmptyField();
                return;
            }

            if (newValue !== currentValue && isEdit) {
                if (!newValue && required) {
                    helpers.resetForm();
                } else {
                    // Ensure that the submitted value is always trimmed,
                    // by doing it here, we avoid having to do it in every onSave callback or via every yup schema
                    const trimmedNewValue = { newValue: newValue }
                    onSave(trimmedNewValue);
                }
            }

            setIsEdit(false);
        }}>
            {
                props => {
                    return <Form style={{ display: isEdit ? 'block' : 'none' }} onKeyDown={(e) => {

                        const enter = 13;
                        const esc = 27;

                        switch (e.keyCode) {
                            case enter:

                                if (preventSubmitOnEnter)
                                    return;

                                if (props.values.newValue != currentValue) {
                                    props.submitForm();
                                }
                                else {
                                    props.resetForm();
                                    setIsEdit(false);
                                }
                                e.preventDefault();

                                break;

                            case esc:
                                props.resetForm();
                                setIsEdit(false);

                                break;
                        }
                    }}>
                        <Field as={as || "input"}
                            innerRef={fieldRef}
                            onBlur={() => {
                                if (props.values.newValue != currentValue) {
                                    props.submitForm();
                                }
                                else {
                                    props.resetForm();
                                    setIsEdit(false);
                                }
                            }}
                            type={component || "text"}

                            name="newValue" />
                        {!hideError && <OfferionErrorMessage name="newValue" />}
                    </Form>
                }
            }

        </Formik>

        {isEdit ? null
            : <span className={className} style={{ width: "100%" }} >

                <span className={classes} style={{ cursor: "pointer" }} onClick={() => {
                    setIsEdit(true);

                    setTimeout(() => {
                        fieldRef.current!.focus()
                    })
                }}>
                    {isInputEmpty ? t(placeholderKey) : currentValue}
                </span>

                {children}

            </span>}
    </>
}