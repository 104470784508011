import { FieldProps } from 'formik'
import React, { useEffect, useState } from 'react'
import { currencyAsText, numberAsText } from '../utils/formatters'

export const CurrencyField = (
    props: FieldProps<number | null | string> & { placeholder: string; onChange: (value: number | '') => void }
) => {
    const [value, setValue] = useState('')

    useEffect(() => {
        if (typeof props.field.value == 'string')
            setValue(Number.isNaN(parseFloat(props.field.value)) ? '' : currencyAsText(props.field.value))
        else if (props.field.value == null) setValue('')
        else setValue(currencyAsText(props.field.value))
    }, [props.field.value])

    function submitValue(fieldValue: string) {
        let finalValue: number | ''

        if (fieldValue) {
            const formattedNumber = fieldValue.replace(',', '.')
            finalValue = Number.parseFloat(formattedNumber)
            setValue(currencyAsText(finalValue))
        } else if (props.field.value === 0) {
            finalValue = 0
            setValue(currencyAsText(0))
        } else {
            finalValue = ''
            setValue('')
        }

        if (props.onChange) props.onChange(finalValue)
        else props.form.setFieldValue(props.field.name, finalValue)
    }
    return (
        <input
            type="text"
            name={props.field.name}
            onKeyDown={e => {
                const key = e.key

                const isValidKey =
                    key === 'Tab' ||
                    key === 'Enter' ||
                    key === 'ArrowLeft' ||
                    key === 'ArrowRight' ||
                    key === '-' ||
                    key === 'Backspace' ||
                    key === 'Delete' ||
                    key === ',' ||
                    key === '.' ||
                    /\d/.test(key) ||
                    e.ctrlKey ||
                    e.metaKey

                if (key === 'Enter') {
                    submitValue(e.currentTarget.value)
                }

                if (!isValidKey) {
                    e.preventDefault()
                }
            }}
            onPaste={e => {
                // note this is pretty similar to submitValue, try unifying it
                e.preventDefault()

                const value = e.clipboardData.getData('text/plain')

                let finalValue: number | ''

                if (value) {
                    const formattedNumber = value.replace(',', '.')

                    finalValue = Number.parseFloat(formattedNumber)
                } else if (props.field.value === 0) {
                    finalValue = 0
                } else {
                    finalValue = ''
                }

                if (finalValue != '' && Number.isNaN(finalValue)) return

                if (typeof finalValue == 'number') setValue(currencyAsText(finalValue))

                if (props.onChange) props.onChange(finalValue)
                else props.form.setFieldValue(props.field.name, finalValue)
            }}
            onFocus={() => {
                setValue(props.field.value == 0 || typeof props.field.value == 'string' ? '' : numberAsText(props.field.value))
            }}
            onChange={e => setValue(e.target.value)}
            onBlur={e => {
                submitValue(e.target.value)
            }}
            placeholder={props.placeholder}
            value={value}
        />
    )
}
