import React, { useState, ReactNode } from 'react'
import throttle from 'lodash/throttle'

type CollapsibleSectionProps = {
    title: ReactNode
    children: ReactNode
    additionalButtonsComponent?: React.ComponentType
    isOpenByDefault?: boolean
}

export const CollapsibleSection = ({ title, children, additionalButtonsComponent, isOpenByDefault = false }: CollapsibleSectionProps) => {
    const [isOpen, setIsOpen] = useState(isOpenByDefault)

    // sometimes the UI would register double clicks, this solves the issue
    const toggleIsOpen = throttle((event: React.MouseEvent<HTMLHeadingElement|HTMLButtonElement>) => {
        event.stopPropagation();
        event.preventDefault();

        setIsOpen(!isOpen)
    }, 30)

    return (
        <div className={isOpen ? 'collapsible-section' : 'collapsible-section  collapsible-section--collapsed'}>
            <div className="collapsible-section__header">
                <h1 className="collapsible-section__title" onClick={toggleIsOpen}>
                    {title}
                </h1>

                <div className="collapsible-section__title-items">
                    {additionalButtonsComponent && (
                        <div className="collapsible-section__additional-button">{React.createElement(additionalButtonsComponent)}</div>
                    )}

                    <button type="button" className="collapsible-section__button button button--gray" onClick={toggleIsOpen}>
                        <span className={isOpen ? 'icon icon__down-blue' : 'icon icon__up'}></span>
                    </button>
                </div>
            </div>

            <div className="collapsible-section__content">{children}</div>
        </div>
    )
}
